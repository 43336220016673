<template>
  <v-container fluid>
    <BreadHeader :title="`Add ${model.view.name}`">
      <v-btn
        :color="hrdcData.HRDC_BLUE_THEME_COLOR"
        class="white--text"
        @click="toggleEventPage()"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
    </BreadHeader>

    <AError :api="apiSubmit" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="eventDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          {{ model.add.name }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Add New {{ model.add.name }} ?
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="addNewEvent()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="eventDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <div>
        <!-- Title -->
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            dense
            filled
            label="Title"
            placeholder="e.g. E-INVOICING CONFERENCE"
            v-model="formData.title"
            :error-messages="errorField.title"
          ></v-text-field>
        </v-col>

        <!-- Venue Address -->
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            dense
            filled
            label="Venue Address"
            placeholder=""
            v-model="formData.venueAddress"
            :error-messages="errorField.venueAddress"
          ></v-text-field>
        </v-col>

        <!-- Speakers -->
        <v-col cols="12" sm="12" md="6">
          <v-select
            dense
            filled
            v-model="formData.speakers"
            :error-messages="errorField.speakers"
            :items="hrdcData.speakers"
            label="Event Speakers"
            multiple
            chips
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
          ></v-select>
        </v-col>

        <!-- Event Start Date -->
        <v-col cols="12" sm="6" md="6">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="date1"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                v-model="formData.eventStartDate"
                :error-messages="errorField.eventStartDate"
                label="Event Start Date (YYYY-MM-DD)"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.eventStartDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(date1)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Language -->
        <v-col cols="12" sm="12" md="6">
          <v-select
            dense
            filled
            v-model="formData.language"
            :error-messages="errorField.language"
            :items="languages"
            item-text="isoLanguageName"
            item-value="isoLanguageCode"
            label="Event Language"
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
          ></v-select>
        </v-col>

        <!-- Status -->
        <v-col class="d-flex" cols="12" sm="12" md="6">
          <v-select
            dense
            filled
            :items="statusOptions"
            label="Status"
            v-model="formData.status"
            :error-messages="errorField.status"
          ></v-select>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            class="ma-2"
            color="success"
            @click="eventDialog = true"
            :loading="apiSubmit.isLoading"
          >
            Add
          </v-btn>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  emits: ["toggleEventPage"],
  data: () => ({
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
    menu2: false,
    formData: {
      title: null,
      status: true,
      speakers: [],
      venueAddress: null,
      eventStartDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      language: "en",
    },
    errorField: {
      title: null,
      status: null,
      eventStartDate: null,
      language: null,
    },
    loadingDialog: false,
    eventDialog: false,
    editMode: false,
    btnDisabled: false,
    eventType: null,
    statusOptions: [
      {
        text: "Active",
        value: true,
      },
      {
        text: "Inactive",
        value: false,
      },
    ],
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;

    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    this.eventType = this.hrdcData.eventType[this.service.key] || null;

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/create-event`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    //EOC
    this.stages = await this.hrdcData.allStages;
    this.hrdcData.rolesAssignation(this.auth.roles);
    if (!this.hrdcData.isHrdcSuperAdmin) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.loadingDialog = !this.loadingDialog;
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    addNewEvent() {
      this.eventDialog = !this.eventDialog;
      this.submit();
    },
    toggleEventPage() {
      this.$emit("toggleEventPage", "EventIndex");
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_tax_summit.data.viewId,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();

      this.apiSubmit.setParams({
        title: this.formData.title,
        eventStartDate: new Date(this.formData.eventStartDate),
        status: this.formData.status,
        userUuid: this.auth.uuid,
        eventType: this.eventType,
        speakers: this.formData.speakers,
        venueAddress: this.formData.venueAddress,
        language: this.formData.language,
      });
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: "Please fill in all the required fields",
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$emit("toggleEventPage", "EventIndex");
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
      this.hideLoadingDialog();
    },
  },
};
</script>
